import React, { useEffect, useState } from 'react';
//import { ErrorMessage } from "@hookform/error-message";
//import { useForm } from "react-hook-form";
import Papa from 'papaparse';
import { jsPDF } from "jspdf";

import logo from './jr-logo.png';
import 'bulma/css/bulma.min.css';
import './App.scss';

/*

IN PROGRESS
- Style form

*/

function App() {

  // Get current date
  let currentDate = new Date();
  let date = currentDate.getDate() + '.' + (currentDate.getMonth() + 1) + '. ' + currentDate.getFullYear();

  // Csv to json with papaparse
  const [parsedCsvData, setParsedCsvData] = useState([]);

  // User code with useState
  const [userCode, setUserCode] = useState('');

  // User name with useState
  const [inputName, setInputName] = useState();
  const [inputSurname, setInputSurname] = useState();
  const fullName = inputName + ' ' + inputSurname;

  // Check if both name fields are filled
  const bothFieldsAreFilled = inputName && inputSurname;

  useEffect(() => {
    Papa.parse('/jr/codes.csv', {
      header: true,
      download: true,
      dynamicTyping: true,
      complete: function (results) {
        //console.log('Results:', results);
        setParsedCsvData(results.data);
      }
    });

  }, []);
  //console.log('Parsed csvFile:', parsedCsvData);

  // Validation message visibility
  const [validationMessage, setValidationMessage] = useState("hidden");

  // Show validation message
  function showValidationMessage() {
    if (matchingImageName) {
      setValidationMessage("hidden");
    }
    else {
      setValidationMessage("visible");
    }
  }

  // Find nested Object that contains a value
  var n;
  let findByValue = (o, val) => {
    if (o === val) return o;
    if (o === NaN || o === Infinity || !o || typeof o !== 'object') return;
    if (Object.values(o).includes(val)) return o;
    for (n of Object.values(o)) {
      const found = findByValue(n, val)
      if (found) return n
    }
  }

  // Check if user code matches
  let codeResult = findByValue(parsedCsvData, userCode);
  
  // If code matches, get associated image name
  let matchingImageName = "";
  if (typeof codeResult !== 'undefined') {
    matchingImageName = codeResult.urkunde;
  }
  //console.log('Matching image name:', matchingImageName);

  // Use image name to get matching image
  let currentImageName = 'ruegen';
  let currentImagePath = `/jr/images/${currentImageName}.jpg`;

  let currentImage = new Image();
  currentImage.src = currentImagePath;

  if (matchingImageName) {
    currentImageName = matchingImageName;
    currentImagePath = `/jr/images/${currentImageName}.jpg`;
    currentImage.src = currentImagePath;
  }

  // Pdf with jspdf: Image, text style and positioning
  const generatePdf = () => {
    const doc = new jsPDF('1', 'pt', 'a4');
    doc.addImage(currentImage, "JPEG", 0, 0, 595, 842);
    doc.setFontSize(18);
    //doc.text(fullName, 180, 469);
    doc.text(fullName, 180, 456);
    doc.setFontSize(14);
    //doc.text(date, 180, 703);
    doc.text(date, 258, 688);
    doc.save("jr-urkunde.pdf");
  };
  // console.log('pdf image:', currentImage);

  // Reset form
  function refreshPage() {
    window.location.reload();
  }

  return (
    <div className="App">
      <main>
        <header>
          <a href="/"><img src={logo} className="app-logo" alt="Junior Ranger Entdeckercode" /></a>
        </header>
        <div className="form text-left">

          <div className="call-to-action">
            <h1 className="title">Entdeckercode</h1>

            <div className="step-1">
              <div className="field has-addons inputbox">

                <div className="control input-wrapper">
                  <input
                    value={userCode}
                    className={matchingImageName ? ("input is-success") : ("input")}
                    type="text"
                    placeholder="Dein Entdeckercode..."
                    onChange={(event) => {
                      setUserCode(event.target.value);
                    }
                    }
                    required
                    disabled={matchingImageName}
                  />
                </div>
                <div className="control">
                  <button
                    className="button is-dark"
                    type="button"
                    onClick={showValidationMessage}
                    disabled={matchingImageName}
                  >
                    Los!
                  </button>
                </div>
              </div>
              <div className={matchingImageName ? "hidden" : "visible"}>
                <span className={`help is-danger ${validationMessage}`}>
                  Leider falsch, versuch es noch einmal!
                </span>
              </div>
            </div>

            {matchingImageName ?
              (
                <div className="step-2">
                  <span className="help is-success">Code passt.</span>
                  <div className="form">
                    <div className="field is-size-6">
                      <p className="congrats">Glückwunsch zum bestandenen Entdeckerheft!</p>
                      <p> Nun gib deinen Namen ein, um deine Urkunde zu bekommen.</p>
                    </div>
                    <div className="field">
                      <label className="label">Vorname</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          placeholder="Dein Vorname"
                          onChange={(event) => {
                            setInputName(event.target.value);
                          }
                          }
                          required
                        />
                      </div>
                    </div>

                    <div className="field">
                      <label className="label">Nachname</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          placeholder="Dein Nachname"
                          onChange={(event) => {
                            setInputSurname(event.target.value);
                          }
                          }
                          required
                        />
                      </div>
                    </div>

                    {bothFieldsAreFilled
                      ? (
                        <div className="field is-grouped">
                          <div className="control">
                            <button className="button" type="reset" onClick={refreshPage}>Zurücksetzen</button>
                          </div>
                          <div className="control">
                            <button className="button is-dark" type="button" onClick={() => { generatePdf(); }}>Download PDF</button>
                          </div>
                        </div>

                      ) : null
                    }

                  </div>
                </div>

              ) : (
                <div className="intro">
                  <p>
                    Gib deinen Entdeckercode ein, um zur Urkunde zu gelangen!
                  </p>
                </div>
              )
            }

          </div>
        </div>

        <div className="preview">
          <div className="box certificate-box">
            {matchingImageName
              ? <img src={currentImagePath} className="certificate-bg" alt="Junior Ranger Urkunde" />
              : <img src={'/jr/images/lars-lotta.png'} className="certificate-bg" alt="Lars und Lotta - Hol dir deine Urkunde" />
            }
            <p className="certificate-fullname">{inputName} {inputSurname}</p>
          </div>

          <div className="controlbox help notification is-info is-light">
            <p>
              Code input: {userCode}<br />
              Name: {inputName}
            </p>
            <p>Parsed Data:</p>
            <div className="align-center">
              <table className="table">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Urkunde</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    parsedCsvData &&
                    parsedCsvData.map((parsedData, index) => (
                      <tr key={index}>
                        <td>{parsedData.code}</td>
                        <td>{parsedData.urkunde}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              <p>
                Matching image: {matchingImageName} <br />
                Validation message: {validationMessage} <br />
                Current image path: {currentImagePath}
              </p>
            </div>
          </div>

        </div>
      </main>
    </div>
  );
}

export default App;